import { Validator, ValidatorDetail } from "types/api/validator";

export const VALIDATOR_INFO: Validator = {
  name: "sei-node-0",
  uptime: "99.99%",
  status: "BOND_STATUS_BONDED",
  commission: "10%",
  consensus_hex_address: "0x00000000000000000000000000000000000000000000",
  operator_address: "sei000000000000000000000000000000000000000000000000",
  voting_power_amount: "0",
  voting_power_percentage: "0%",
  participation_ratio: "0/11",
  participation_percentage: "0%",
  image_url: null,
};

export const VALIDATORS_STATS = [
  {
    label: "Total validators",
    value: 30,
  },
  {
    label: "Active validators",
    value: 29,
  },
  {
    label: "Inactive validators",
    value: 1,
  },
  {
    label: "Jailed validators",
    value: 1,
  },
];

export const VALIDATOR_DETAIL: ValidatorDetail = {
  id: 0,
  account_address: "sei000000000000000000000000000000000000000",
  consensus_address: "seivalcons000000000000000000000000000000000000000",
  consensus_hex_address: "0x0000000000000000000000000000000000000000",
  percent_voting_power: 0.0,
  operator_address: "seivaloper000000000000000000000000000000000000000",
  missed_blocks_counter: 0,
  min_self_delegation: 0,
  jailed_until: "1970-01-01T00:00:00+00:00",
  jailed: false,
  image_url: "validator-default.svg",
  index_offset: 0,
  description: {
    details: "",
    moniker: "sei-node-0",
    website: "",
    identity: "",
    security_contact: "",
  },
  delegators_last_height: 0,
  delegators_count: 0,
  consensus_pubkey: {
    key: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=",
    type: "/cosmos.crypto.ed25519.PubKey",
  },
  delegator_shares: 0,
  created_at: "1970-01-01T00:00:00+00:00",
  commission: {
    update_time: "1970-01-01T00:00:00.000000000Z",
    commission_rates: {
      rate: "0.000000000000000000",
      max_rate: "0.000000000000000000",
      max_change_rate: "0.000000000000000000",
    },
  },
  uptime: 0.0,
  updated_at: "1970-01-01T00:00:00+00:00",
  unbonding_time: "1970-01-01T00:00:00+00:00",
  unbonding_height: 0,
  tombstoned: false,
  tokens: 0,
  status: "BOND_STATUS_UNBONDED",
  start_height: 0,
  self_delegation_balance: 0,
};
