/* eslint-disable @typescript-eslint/ban-types */
import _, { cloneDeep, debounce, DebouncedFuncLeading, throttle } from "lodash";
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

function deepCompareEquals(a: any, b: any) {
  // TODO: implement deep comparison here
  // something like lodash
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef<any>();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = cloneDeep(value);
  }

  return ref.current;
}

export type Options = { defer?: boolean } & (
  | { debounce?: number; throttle?: never }
  | { debounce?: never; throttle?: number }
);

const useKeeper = (options?: Options) => {
  return useMemo<
    DebouncedFuncLeading<(dependencies: DependencyList) => DependencyList>
  >(() => {
    if (options?.throttle) {
      return throttle((dependencies) => dependencies, options?.throttle);
    }
    if (options?.debounce) {
      return debounce((dependencies) => dependencies, options?.debounce);
    }
    return ((dependencies) => dependencies) as DebouncedFuncLeading<
      (dependencies: DependencyList) => DependencyList
    >;
  }, [options?.throttle, options?.debounce]);
};

export function useShallowEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
  options?: Options,
) {
  const isMountingRef = useRef(!options?.defer ? true : false);
  useEffect(() => {
    if (!options?.defer) return;
    isMountingRef.current = true;
  }, []);

  const keeperFunction = useKeeper(options);

  useEffect(
    () => {
      if (isMountingRef.current) {
        if (!callback) return;
        const onUnmout = callback();
        return () => {
          onUnmout?.();
          callback = null as any;
        };
      } else {
        isMountingRef.current = false;
      }
    },
    keeperFunction(dependencies.map(useDeepCompareMemoize)),
  );
}

export function useShallowLayoutEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
  options?: Options,
) {
  const memorizeRef = useRef<DependencyList>(
    options?.defer ? dependencies : [Math.random()],
  );

  if (!deepCompareEquals(dependencies, memorizeRef.current)) {
    memorizeRef.current = cloneDeep(dependencies);

    callback();
  }
}

export function useShallowMemo<T>(
  factory: () => T,
  dependencies: DependencyList,
  // options?: Options & { callback?: EffectCallback; defer?: boolean },
) {
  // const isMountingRef = useRef(!options?.defer ? true : false);
  // useEffect(() => {
  //   if (!options?.defer) return;
  //   isMountingRef.current = true;
  // }, []);

  return useMemo<T>(() => {
    // if (isMountingRef.current) {
    //   options?.callback?.();
    // } else {
    //   isMountingRef.current = false;
    // }

    return factory();
  }, dependencies.map(useDeepCompareMemoize));
}

export function useShallowLayoutMemo<T>(
  factory: () => T,
  dependencies: DependencyList,
) {
  const memorizeRef = useRef<DependencyList>([Math.random()]);
  const memorizeResultRef = useRef<T>(factory());
  if (!deepCompareEquals(dependencies, memorizeRef.current)) {
    memorizeRef.current = cloneDeep(dependencies);
    memorizeResultRef.current = factory();
  }
  return memorizeResultRef.current;
}

export const useShadowCallback = <T extends AnyFunction>(
  callback: T,
  dependencies: DependencyList,
  options?: Options,
) => {
  const keeperFunction = useKeeper(options);

  return useCallback(
    callback,
    keeperFunction(dependencies.map(useDeepCompareMemoize)),
  );
};

export const useShallowHandler = <T extends AnyFunction>(
  callback: T,
  dependencies: DependencyList,
  options?: Options,
) => {
  const keeperFunction = useKeeper();

  const handler = useCallback(
    callback,
    keeperFunction(dependencies.map(useDeepCompareMemoize)),
  );

  const setQuery = useCallback(
    options?.throttle
      ? throttle(handler, options?.throttle)
      : debounce(handler, options?.debounce || 100),
    [options?.throttle, options?.debounce],
  );
  return setQuery;
};
