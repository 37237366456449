import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";

import useApiQuery from "lib/api/useApiQuery";
import useThemeColors from "ui/shared/monaco/utils/useThemeColors";

import useIsMobile from "lib/hooks/useIsMobile";
import { HOMEPAGE_STATS } from "stubs/stats";
import TabFloat from "ui/shared/Tabs/TabFloat";
import ChainIndicatorChartContainerV3 from "./ChainIndicatorChartContainerV3";
import { UHomepageIndicatorId } from "./types";
import useFetchChartData from "./useFetchChartData";
import indicators from "./utils/indicators";

const ChainIndicatorsV3 = () => {
  const themes = useThemeColors();
  const [selectedIndicator, selectIndicator] =
    useState<UHomepageIndicatorId>("daily_txs");

  const queryResult = useFetchChartData(selectedIndicator);

  const statsQueryResult = useApiQuery("homepage_stats", {
    queryOptions: { placeholderData: HOMEPAGE_STATS },
  });

  const isMobile = useIsMobile();

  return (
    <Flex
      width="100%"
      height="100%"
      bgColor="white"
      borderWidth="1px"
      borderStyle="solid"
      flexDirection="column"
      borderTopLeftRadius="8px"
      borderTopRightRadius="8px"
      borderBottomLeftRadius="8px"
      borderBottomRightRadius="8px"
      borderColor={themes["border.color"]}
    >
      <TabFloat
        isSetOnRouter={false}
        id="stats_tab"
        value={selectedIndicator}
        onChange={(newId) => selectIndicator(newId)}
        indicatorProps={{
          background: "transparent",
          height: "0.125rem",
          width: "full",
          paddingX: { base: 4, lg: 4 },
          display: "flex",
          alignItems: "flex-end",
          children: (
            <Box
              background="primaryLightButton"
              width={{ base: "5rem", lg: "full" }}
              height="0.125rem"
              borderTopRadius="0.25rem"
            ></Box>
          ),
        }}
        orientation={isMobile ? "vertical" : "horizontal"}
        tabListProps={{
          height: "unset",
        }}
        tabs={useMemo(
          () =>
            Object.entries(indicators).map(
              ([indicatorId, { title, value }]) => {
                return {
                  id: indicatorId as UHomepageIndicatorId,
                  tabProps: {
                    minWidth: "unset",
                    height: "4rem",
                    paddingX: { base: 2, lg: 4 },
                    marginX: { base: 2, lg: 0 },
                    paddingTop: 4,
                    paddingBottom: 1,
                    flex: 1,
                    borderRadius: "none",
                    borderBottomWidth: "1px",
                    borderBottomColor: "neutral.light.3",
                    justifyContent: { base: "flex-start", "2lg": "center" },
                  },
                  title: ({ isActive }) => (
                    <Flex
                      alignItems="flex-start"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Text color="neutral.light.6" textStyle="8125">
                        {title}
                      </Text>
                      <Skeleton
                        isLoaded={
                          !(
                            statsQueryResult.isLoading ||
                            statsQueryResult.isPlaceholderData
                          )
                        }
                      >
                        <Text
                          color={
                            isActive ? "neutral.light.8" : "neutral.light.6"
                          }
                          textStyle="1"
                          fontWeight={500}
                        >
                          {value(statsQueryResult.data!)}
                        </Text>
                      </Skeleton>
                    </Flex>
                  ),
                  component: null,
                };
              },
            ),
          [selectedIndicator, statsQueryResult.data],
        )}
      ></TabFloat>
      <ChainIndicatorChartContainerV3
        data={queryResult.data}
        isError={queryResult.isError}
        isPending={queryResult.isPending}
        isLoading={queryResult.isLoading}
        isPlaceholderData={queryResult.isPlaceholderData}
        dataUpdatedAt={queryResult.dataUpdatedAt}
      />
    </Flex>
  );
};

export default ChainIndicatorsV3;
