import { Options, useShallowHandler } from "lib/hooks/useShallow";
import { omit } from "lodash";
import Router from "next/router";

export const useSetQuery = function (
  queryName: any,
  options?: Options & {
    cleanUp?: { keepQueries?: string[] };
    removeQueries?: string[];
  },
) {
  return useShallowHandler(
    (value: any) => {
      return Router.replace(
        {
          pathname: Router.pathname,
          query: omit(
            {
              ["chain"]: Router.query.chain,
              ...(options?.cleanUp ? {} : Router.query),
              ...Object.fromEntries(
                (options?.cleanUp?.keepQueries || []).map((key) => [
                  key,
                  Router.query[key],
                ]),
              ),
              [queryName]: String(value),
            },
            ...(options?.removeQueries || []),
          ),
        },
        undefined,
        { scroll: false },
      );
    },
    [queryName],
    {
      ...options,
      debounce: (options?.throttle ? 0 : (options?.debounce ?? 100)) as any,
    },
  );
};
