import type { ArrayElement } from "types/utils";

import type { PaginatedResources, PaginatedResponse } from "lib/api/resources";

export function generateListStub<Resource extends PaginatedResources>(
  // stub: ArrayElement<PaginatedResponse<Resource>["items"]>,
  stub: ArrayElement<any>,
  num = 50,
  rest: Omit<PaginatedResponse<Resource>, "items">,
) {
  return {
    items: Array(num).fill(
      stub,
    ) as ArrayElement<// PaginatedResponse<Resource>["items"][number]
    any>,
    ...rest,
  } as any;
}
