import React from "react";

import type { Route } from "nextjs-routes";

import useAdblockDetect from "lib/hooks/useAdblockDetect";
import useGetCsrfToken from "lib/hooks/useGetCsrfToken";
import * as mixpanel from "lib/mixpanel";
import { init as initSentry } from "lib/sentry/config";
import { memo } from "react";

type Props = Route & {
  children?: React.ReactNode;
};

initSentry();

const PageNextJs = (props: Props) => {
  useGetCsrfToken();
  useAdblockDetect();

  const isMixpanelInited = mixpanel.useInit();
  mixpanel.useLogPageView(isMixpanelInited);

  return props.children;
};

export default memo(PageNextJs);
