import type { TooltipProps } from "@chakra-ui/react";
import {
  Box,
  Flex,
  LightMode,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

import Hint from "ui/shared/Hint";
import type { IconName } from "ui/shared/IconSvg";
import IconSvg from "ui/shared/IconSvg";

type Props = {
  icon: IconName;
  title: string;
  value: string;
  className?: string;
  tooltipLabel?: React.ReactNode;
  url?: string;
  isLoading?: boolean;
};

const TOOLTIP_PROPS: Partial<TooltipProps> = {
  hasArrow: false,
  borderRadius: "md",
  placement: "bottom-end",
  offset: [0, 0],
  bgColor: "blackAlpha.900",
};

const StatsItem = ({
  icon,
  title,
  value,
  className,
  tooltipLabel,
  url,
  isLoading,
}: Props) => {
  const infoColor = useColorModeValue("gray.600", "gray.400");
  return (
    <Box
      gap={4}
      height="full"
      className={className}
      color={useColorModeValue("black", "white")}
      position="relative"
      paddingX={{ base: 4, md: 5 }}
      paddingY={{ base: 4, md: 2 }}
      borderRightWidth={{ base: "0px", md: "1px" }}
      _last={{
        borderRightWidth: "0px",
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        boxSize="full"
        borderColor="neutral.light.3"
        rowGap={4}
        {...(url && !isLoading
          ? {
              as: Link,
              href: url,
            }
          : {})}
      >
        <IconSvg
          name={icon}
          boxSize={"32px"}
          isLoading={isLoading}
          borderRadius="base"
        />
        <Flex flexDirection="column" alignItems="start" gap={1}>
          <Skeleton isLoaded={!isLoading} borderRadius="base">
            <Text
              textStyle="175"
              color={useColorModeValue("neutral.light.8", "white")}
            >
              {value}
            </Text>
          </Skeleton>
          <Skeleton
            isLoaded={!isLoading}
            color="neutral.light.7"
            borderRadius="base"
            display="flex"
            gap={1}
            alignItems="center"
            height={5}
          >
            <Text textStyle="8125">{title}</Text>
            {tooltipLabel && !isLoading && (
              <LightMode>
                <Hint
                  label={tooltipLabel}
                  tooltipProps={TOOLTIP_PROPS}
                  boxSize={6}
                  color={infoColor}
                />
              </LightMode>
            )}
          </Skeleton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default StatsItem;
