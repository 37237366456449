import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import useApiQuery, { getResourceKey } from "lib/api/useApiQuery";
import { useLastSync } from "lib/hooks/useLastSync";
import type { SocketMessage } from "lib/socket/types";
import useSocketChannel from "lib/socket/useSocketChannel";
import useSocketMessage from "lib/socket/useSocketMessage";
import dynamic from "next/dynamic";
import React from "react";
import { BLOCK } from "stubs/block";
import type { Block } from "types/api/block";
import Empty from "ui/shared/Empty";
import Hint from "ui/shared/Hint";
import LinkInternal from "ui/shared/LinkInternal";
import useThemeColors from "ui/shared/monaco/utils/useThemeColors";

const LatestBlocksItem = dynamic(() => import("./LatestBlocksItem"), {
  ssr: false,
});

const LatestBlocks = () => {
  const BLOCK_MAX_COUNT = 6;
  const themeColors = useThemeColors();
  const { data: validator_stats_data } = useApiQuery("validators_stats");

  const { data, isPlaceholderData, isError, dataUpdatedAt } = useApiQuery(
    "blocks",
    {
      queryParams: {
        limit: BLOCK_MAX_COUNT,
      },
      queryOptions: {
        placeholderData: {
          items: Array(BLOCK_MAX_COUNT).fill(BLOCK),
          next_page_params: {
            block_number: 6,
            items_count: 6,
          },
        },
      },
    },
  );

  const queryClient = useQueryClient();

  const handleNewBlockMessage: SocketMessage.NewBlock["handler"] =
    React.useCallback(
      (payload) => {
        queryClient.setQueryData(
          getResourceKey("blocks"),
          (prevData: Array<Block> | undefined) => {
            const newData = prevData ? [...prevData] : [];

            if (
              newData.some((block) => block.height === payload.block.height)
            ) {
              return newData;
            }

            return [payload.block, ...newData]
              .sort((b1, b2) => b2.height - b1.height)
              .slice(0, BLOCK_MAX_COUNT);
          },
        );
      },
      [queryClient, BLOCK_MAX_COUNT],
    );

  const channel = useSocketChannel({
    topic: "blocks:new_block",
    isDisabled: isPlaceholderData || isError,
  });
  useSocketMessage({
    channel,
    event: "new_block",
    handler: handleNewBlockMessage,
  });

  const lastSync = useLastSync(dataUpdatedAt, [data]);

  return (
    <Box flexShrink={0} flex={1}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
        paddingX={{ base: 4, xl: 6 }}
        paddingY={{ base: 3, xl: 3 }}
        height={{ lg: "5.425rem" }}
      >
        <Flex
          direction={"row"}
          width="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center" gap={2}>
            <Heading
              as="h4"
              size="sm"
              fontWeight="bold"
              fontSize="1.125rem"
              lineHeight="1.75rem"
              color="neutral.light.7"
            >
              Latest blocks
              <Hint ml={2} label={`Last sync: ${lastSync}`}></Hint>
              {validator_stats_data && validator_stats_data[1] ? (
                <Flex
                  direction={"row"}
                  width={"full"}
                  justifyContent="space-between"
                  alignItems={{ base: "center", lg: "flex-start" }}
                >
                  <Flex
                    width="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex alignItems="center" gap={1}>
                      <Text as="span" textStyle="875">
                        Allocated validators
                      </Text>{" "}
                      <Text as="span" textStyle="875">
                        <LinkInternal
                          href="/validators"
                          marginTop={-6}
                          position="relative"
                          textStyle="875"
                          color="secondary.03.text"
                        >
                          {validator_stats_data
                            ? validator_stats_data[1].value
                            : ""}
                        </LinkInternal>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              ) : null}
            </Heading>
          </Flex>
          <LinkInternal
            href="/blocks"
            marginTop={-6}
            color="accent.blue"
            position="relative"
          >
            View all
          </LinkInternal>
        </Flex>
      </Flex>

      <Grid
        borderStyle="solid"
        borderTopWidth="1px"
        borderColor={themeColors["border.color"]}
        templateRows={{
          base: "repeat(6, 6.75rem)",
          lg: "repeat(6, 4.25rem)",
        }}
        height={{ base: "40.5rem", lg: "25.5rem" }}
        overflow="hidden"
      >
        {isError ? (
          <Empty width="full" gridRow="span 6" height="full"></Empty>
        ) : (
          data?.items
            ?.slice(0, BLOCK_MAX_COUNT)
            .map((block, index) => (
              <LatestBlocksItem
                key={block.height + (isPlaceholderData ? String(index) : "")}
                block={block}
                isLoading={isPlaceholderData}
              />
            ))
        )}
      </Grid>
    </Box>
  );
};

export default LatestBlocks;
