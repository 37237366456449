import moment, { getTimeZone } from "lib/date/moment";
import { useMemo } from "react";

export const useLastSync = (dataUpdatedAt: any, watchList: any[]) => {
  return useMemo(() => {
    const currentTimeZone = getTimeZone();
    return moment(dataUpdatedAt).format(
      `DD/MM/YYYY HH:mm:ss [UTC${currentTimeZone}]`,
    );
  }, watchList);
};
