import { Grid } from "@chakra-ui/react";
import BigNumber from "bignumber.js";

import { route } from "nextjs-routes";

import config from "configs/app";
import useApiQuery from "lib/api/useApiQuery";
import { WEI } from "lib/consts";
import { HOMEPAGE_STATS } from "stubs/stats";
import GasInfoTooltipContent from "ui/shared/GasInfoTooltipContent/GasInfoTooltipContent";

import StatsItem from "./StatsItem";

const hasGasTracker = config.UI.homepage.showGasTracker;
const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;

const Stats = () => {
  const { data, isPlaceholderData, isError } = useApiQuery("homepage_stats", {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
    },
  });
  const statsCounterQuery = useApiQuery("stats_counters", {
    queryOptions: {},
  });

  if (isError || statsCounterQuery.isError) {
    return null;
  }

  let content;

  let itemsCount = 4;
  !hasGasTracker && itemsCount--;
  !hasAvgBlockTime && itemsCount--;

  if (data) {
    !data.gas_prices && itemsCount--;
    data.rootstock_locked_btc && itemsCount++;

    const gasLabel =
      hasGasTracker && data.gas_prices ? (
        <GasInfoTooltipContent gasPrices={data.gas_prices} />
      ) : null;

    content = (
      <>
        {hasAvgBlockTime && (
          <StatsItem
            icon="average-time"
            title="Average block time"
            value={`${Number(
              statsCounterQuery.data?.counters.find(
                (x) => x.id === "averageBlockTime",
              )?.value,
            ).toFixed(1)}s`}
            isLoading={statsCounterQuery.isLoading}
          />
        )}
        <StatsItem
          icon="total-transaction"
          title="Total transactions (EVM)"
          value={Number(
            statsCounterQuery.data?.counters.find((x) => x.id === "totalTxns")
              ?.value,
          ).toLocaleString(undefined, {
            maximumFractionDigits: 0,
            notation: "compact",
          })}
          url={route({ pathname: "/txs" })}
          isLoading={statsCounterQuery.isLoading}
        />
        <StatsItem
          icon="wallet-green"
          title="Wallet addresses (EVM)"
          value={Number(data.total_addresses).toLocaleString()}
          isLoading={isPlaceholderData}
        />
        {hasGasTracker && data.gas_prices && (
          <StatsItem
            icon="gas-tracker"
            title="Gas tracker"
            value={
              data.gas_prices.average !== null
                ? `${Number(data.gas_prices.average).toLocaleString()}`
                : "N/A"
            }
            tooltipLabel={gasLabel}
            isLoading={isPlaceholderData}
          />
        )}
        {data.rootstock_locked_btc && (
          <StatsItem
            icon="coins/bitcoin"
            title="BTC Locked in 2WP"
            value={`${BigNumber(data.rootstock_locked_btc)
              .div(WEI)
              .dp(0)
              .toFormat()} RBTC`}
            isLoading={isPlaceholderData}
          />
        )}
      </>
    );
  }

  return (
    <Grid
      flex={1}
      height={{ base: "unset", md: "10.75rem" }}
      gridTemplateColumns={{
        md: `repeat(${itemsCount}, 1fr)`,
        base: "1fr 1fr",
      }}
      gridTemplateRows={{ base: "50% 50%", lg: "100%" }}
      backgroundColor="white"
      border="1px solid"
      borderColor="neutral.light.3"
      rounded="0.5rem"
      paddingY={{ base: 0, md: 5 }}
    >
      {content}
    </Grid>
  );
};

export default Stats;
