import Head from "next/head";
import React from "react";

import type { Route } from "nextjs-routes";

import * as metadata from "lib/metadata";

type Props = Route;

const BuildHead = (props: Props) => {
  const { title, description, opengraph } = metadata.generate(props);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* OG TAGS */}
      <meta property="og:title" content={opengraph.title} />
      {opengraph.description && (
        <meta property="og:description" content={opengraph.description} />
      )}
      <meta property="og:image" content="/static/og_placeholder.png" />
      <meta property="twitter:image" content="/static/og_placeholder.png" />
      <meta property="og:image:width" content="250" />
      <meta property="og:image:height" content="250" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
    </Head>
  );
};

export default BuildHead;
