import { Box, Flex, Skeleton } from "@chakra-ui/react";
import type { UseQueryResult } from "@tanstack/react-query";
import type { TimeChartDataItem } from "./types";

import DataFetchAlert from "ui/shared/DataFetchAlert";
import Hint from "ui/shared/Hint";

import { useLastSync } from "lib/hooks/useLastSync";
import ChainIndicatorChartV3 from "./ChainIndicatorChartV3";

type Props = Pick<
  UseQueryResult<TimeChartDataItem | undefined>,
  | "data"
  | "isError"
  | "isPending"
  | "isLoading"
  | "isPlaceholderData"
  | "dataUpdatedAt"
>;

const ChainIndicatorChartContainerV3 = ({
  data,
  isError,
  isPending,
  dataUpdatedAt,
}: Props) => {
  const lastSync = useLastSync(dataUpdatedAt, [data]);

  return (
    <Skeleton
      isLoaded={!isPending}
      paddingX={{ base: 2, lg: 3 }}
      paddingTop={{ base: 2, lg: 3 }}
      paddingBottom={{ base: 1, lg: 2 }}
      flexDirection="column"
      flex={1}
      position="relative"
      height={{ base: "12.25rem", lg: "13.25rem", "2lg": "14.75rem" }}
    >
      {isError && <DataFetchAlert />}

      <Flex flexDirection="column" height="full" width="full" gap="0.15rem">
        {!isError && (
          <Box
            alignItems="flex-start"
            height="full"
            width="full"
            flexGrow={1}
            position="relative"
            flex={1}
          >
            {!isPending && <ChainIndicatorChartV3 data={data!} />}
          </Box>
        )}
        {!isPending && !isError && (
          <Flex
            alignItems="center"
            fontSize={{ base: "9px", md: "xs" }}
            bg="#80808021"
            borderRadius="8px"
            paddingY="0px"
            paddingRight="2px"
            paddingLeft="6px"
            flexWrap="wrap"
            display="inline-flex"
            width="fit-content"
            columnGap="1ch"
          >
            <Box flexShrink={0}>Market data is powered by Coingecko API</Box>{" "}
            <Hint
              label={`Last sync: ${lastSync}`}
              boxSize={4}
              color="gray.600"
              tooltipProps={{
                offset: [0, 0],
                hasArrow: false,
                fontSize: "12px",
                borderRadius: "md",
                placement: "bottom-end",
                bgColor: "blackAlpha.900",
              }}
            />
          </Flex>
        )}
      </Flex>
    </Skeleton>
  );
};

export default ChainIndicatorChartContainerV3;
