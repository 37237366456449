import { Box, Flex, Grid, Heading } from "@chakra-ui/react";
import useApiQuery from "lib/api/useApiQuery";
import dynamic from "next/dynamic";
import { useState } from "react";
import { TX } from "stubs/tx";
import { generateListStub } from "stubs/utils";
import Empty from "ui/shared/Empty";
import LinkInternal from "ui/shared/LinkInternal";
import useThemeColors from "ui/shared/monaco/utils/useThemeColors";
import TabFloat from "ui/shared/Tabs/TabFloat";

const LatestTxsItem = dynamic(() => import("./LatestTxsItem"), { ssr: false });

const LatestTxs = () => {
  const themeColors = useThemeColors();
  const [txTab, setTxTab] = useState<"evm" | "native_sei">("evm");

  const txsCount = 6;
  const { data, isPlaceholderData, isError } = useApiQuery(
    "latest_txs_validated",
    {
      queryParams: {
        type: txTab === "native_sei" ? ["Cosmos"] : ["EVM"],
        limit: txsCount,
      },
      queryOptions: {
        placeholderData: generateListStub<"latest_txs_validated">(TX, 6, {
          next_page_params: {
            block_number: 9005713,
            index: 5,
            items_count: 6,
            filter: "validated",
          },
        }),
      },
    },
  );

  return (
    <Box flexShrink={0} flex={1}>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        paddingX={{ base: 4, xl: 6 }}
        paddingY={{ base: 3, xl: 3 }}
        paddingBottom="0px !important"
        height={{ lg: "5.425rem" }}
        borderStyle="solid"
        borderBottomWidth="1px"
        borderColor={themeColors["border.color"]}
        gap={2}
      >
        <Flex width="full" justifyContent="space-between" alignItems="center">
          <Heading
            as="h4"
            size="sm"
            fontWeight="bold"
            fontSize="1.125rem"
            lineHeight="1.75rem"
            color="neutral.light.7"
            marginBottom={{ base: 1.5 }}
          >
            Latest transactions
          </Heading>
          <LinkInternal
            href={txTab === "evm" ? "/txs" : "/txs?tab=native_sei"}
            color="accent.blue"
            position="relative"
          >
            View all
          </LinkInternal>
        </Flex>
        <TabFloat
          id="latest_transactions"
          value={txTab}
          isSetOnRouter={false}
          onChange={(newId) => {
            setTxTab(newId);
          }}
          tabs={[
            { id: "evm", title: "EVM", component: undefined },
            { id: "native_sei", title: "Native SEI", component: undefined },
          ]}
        ></TabFloat>
      </Flex>
      <Grid
        templateRows={{
          base: "repeat(6, 6.75rem)",
          lg: "repeat(6, 4.25rem)",
        }}
        height={{ base: "40.5rem", lg: "25.5rem" }}
        overflow="hidden"
      >
        {isError ? (
          <Empty width="full" gridRow="span 6" height="full"></Empty>
        ) : (
          data?.items
            ?.slice(0, 6)
            .map((transaction, index) => (
              <LatestTxsItem
                tx={transaction}
                isLoading={isPlaceholderData}
                key={transaction.hash + (isPlaceholderData ? index : "")}
              />
            ))
        )}
      </Grid>
    </Box>
  );
};

export default LatestTxs;
