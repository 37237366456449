import BigNumber from "bignumber.js";
import type { IIndicators, TChainIndicator } from "../types";

import config from "configs/app";
import IconSvg from "ui/shared/IconSvg";
import { formatNumberWithSuffix } from "lib/bignumber/format";
import type { TimeChartItem } from "../types";

export const sortByDateDescV3 = (a: TimeChartItem, b: TimeChartItem) => {
  return a.date.getTime() - b.date.getTime();
};

const dailyTxsIndicator: TChainIndicator<"daily_txs"> = {
  title: "Daily transactions",
  value: (stats) =>
    Number(stats?.transactions_today || "0").toLocaleString(undefined, {
      maximumFractionDigits: 2,
      notation: "compact",
    }),
  icon: (
    <IconSvg
      name="daily-transaction"
      boxSize={6}
      bgColor="#56ACD1"
      borderRadius="base"
      color="white"
    />
  ),
  hint: `Number of transactions yesterday (0:00 - 23:59 UTC). The chart displays daily transactions for the past 30 days.`,
  api: {
    resourceName: "homepage_chart_txs",
    dataFn: (response) => ({
      items: response.chart_data
        .map((item) => ({
          date: new Date(item.date),
          value: BigNumber(item.tx_count),
        }))
        .sort(sortByDateDescV3),
      name: "Tx/day",
      valueFormatter: (x: BigNumber) =>
        formatNumberWithSuffix(x, { decimalPlaces: 2 }).fullyFormattedNumber,
    }),
  },
};

const coinPriceIndicator: TChainIndicator<"coin_price"> = {
  title: `${
    config.chain.governanceToken.symbol || config.chain.currency.symbol
  } price`,
  value: (stats) =>
    "$" +
    Number(stats?.coin_price || "0").toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
    }),
  icon: <img src="/icons/native-coin.svg"></img>,
  hint: `${
    config.chain.governanceToken.symbol || config.chain.currency.symbol
  } token daily price in USD.`,
  api: {
    resourceName: "homepage_chart_market",
    dataFn: (response) => ({
      items: response.chart_data
        .map((item) => ({
          date: new Date(item.date),
          value: BigNumber(item.closing_price),
        }))
        .sort(sortByDateDescV3),
      name: `${
        config.chain.governanceToken.symbol || config.chain.currency.symbol
      } price`,
      valueFormatter: (x: BigNumber) =>
        "$" +
        formatNumberWithSuffix(x, { decimalPlaces: 6 }).fullyFormattedNumber,
    }),
  },
};

const marketPriceIndicator: TChainIndicator<"market_cap"> = {
  title: "Market cap",
  value: (stats) =>
    "$" +
    Number(stats?.market_cap || "0").toLocaleString(undefined, {
      maximumFractionDigits: 2,
      notation: "compact",
    }),
  icon: <IconSvg name="market-cap" boxSize={8} />,
  hint: "The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market. Market Cap = Current Price x Circulating Supply.",
  api: {
    resourceName: "homepage_chart_market",
    dataFn: (response) => ({
      items: response.chart_data
        .map((item) => ({
          date: new Date(item.date),
          value: item.market_cap
            ? BigNumber(item.market_cap)
            : BigNumber(item.closing_price).multipliedBy(
                response.available_supply,
              ),
        }))
        .sort(sortByDateDescV3),
      name: "Market cap",
      valueFormatter: (x: BigNumber) =>
        "$" +
        formatNumberWithSuffix(x, { decimalPlaces: 6 }).fullyFormattedNumber,
    }),
  },
};

const INDICATORS: IIndicators = {
  daily_txs: dailyTxsIndicator as any,
  coin_price: coinPriceIndicator as any,
  market_cap: marketPriceIndicator as any,
};

export default INDICATORS;
